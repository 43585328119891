@import '../../../../styles/variables';

.makeStyles-root-1, .makeStyles-root-2, .makeStyles-root-3, .makeStyles-root-4, .makeStyles-root-5{
    @media #{$phone} {
        min-height: 50px;
    }
    @media #{$wide} {
        min-height: 80px;
    }

    .MuiPaper-root{        
        padding-top: 8px;
        padding-bottom: 8px;

        @media #{$phone} {
            padding-left: 0px;
            padding-right: 0px;    
        }
        @media #{$wide} {
            padding-left: 12px;
            padding-right: 12px;
        }

        .MuiStep-root{
            .MuiStepLabel-root{
                .MuiStepLabel-labelContainer{
                    .MuiTypography-root{
                        @media #{$phone} {
                            display: none;
                        }  
                    }
                }
            }

            @media #{$phone} {
                padding-left: 0px;
                padding-right: 0px;    
            }   
        }
    }
}

.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    @media #{$phone} {
        font-size: 10px !important;  
    }  
    margin-top: 10px !important;
}

.MuiStepper-root {
    @media #{$phone} {
        padding-left: 0px !important;
        padding-right: 0px !important;    
    }   
}