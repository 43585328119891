@import '../../../../styles/variables';

.spinner{
    text-align: center !important;
}

.spinner > img{
    width: 100px;
    margin-top: 12%;
    @media #{$phone} {
        margin-bottom: 20%;
        margin-top: 33%;
    }
}

.successImage{
    margin-top: 1rem !important;
}

.errorMessage{
    margin-top: 20%;
}