@import '../../styles/variables';

.title-main{
    @media #{$wide} {        
        margin-top: -30px;
    }
    @media #{$phone-tablet} {        
        margin-top: 40px;
    }
    .title-row-title{
        @media #{$phone} {        
            padding-top: 3rem !important;    
        }
        h1{
            font-size: 45px;
            font-weight: 500;
            color: #fff;
            line-height: 46px;
        }
    }
    .title-row-description > .col-12 > .row > h5{
        font-size: 20px;
        font-weight: 300;
        color: #fff;
        line-height: 30px;
    }
}

.logo-movistar{
    width: 66%;
    @media #{$wide} {        
        margin-bottom: 40px;  
    }
}

.MuiStepper-root {
    padding-bottom: 24px !important;
    padding-top: 14px !important;
}