@import '../front/form/form';
@import '../front/title/title';
@import './variables';

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

html, body, #root, .App{
    @media #{$phone} {
        height: 100%!important;
        background-color: rgb(0, 107, 138);
    }
}
body{	
    font-family: "Roboto", sans-serif;
}
.App{
    .container-fluid{
        height: inherit;
        #hero-diagonal {
            position: relative;
            &::before {
                @media #{$phone} {
                    display: none;
                }
                content: '';
                position: fixed;
                top: -50px;
                left: 0;
                width: 100%;
                height: calc(100% + 100px);
                clip-path: polygon(100% 0, 40% 0, 100% 65%);
            }
            #hero-content {
                position: relative;
                z-index: 1;
            }
        }
        @media #{$wide} {
            height: 100vh;
        }
        .col-lg-12{
            .row{
                .col-form{
                    @media #{$wide} {
                        padding-left: 3rem !important;
                        padding-right: 3rem !important;
                    }
                    @media #{$phone} {
                        padding-left: 1rem !important;
                        padding-right: 1rem !important;
                    }
                    padding-bottom: 4rem !important;
                    padding-top: 3rem !important;
                    margin-top: auto;
                    margin-bottom: auto;
                    border-radius: 0.25rem !important;
                    background-color: #fff !important;             
                    @media #{$extra-wide} {
                        padding-bottom: 7rem !important;   
                    }
                }
            }
        }
    }
}
*:focus {
    outline: none !important;
    outline: 0 !important;
}
@media #{$ipad-portrait} {
    html{
        background-color: #146c8b;
    }
    .slick-slide.slick-active.slick-center.slick-current{
        margin-left: -22px;
    }
}
@media #{$ipad-pro-portrait} {
    .container-fluid > .col-lg-12 > .row{
        padding-top: 310px;
    }
    .row.slide.activeSlide{
        margin-left: -37px;
    }
    .col-form{
        max-height: 630px;
    }
    .title-main {
        margin-top: -160px;
    }
    .plan-name{
        padding-top: 6px;
        line-height: 20px;
    }
    .plan-container {
        padding-left: 0px;
    }
    #officePhoneNumber, #phoneNumber{
        margin-top: 16px;
    }
}
@media #{$ipad-landscape} {
    .row.slide.activeSlide{
        margin-left: -37px;
    }
    .container-fluid > .col-lg-12 > .row{
        padding-top: 50px;
    }
    .title-main {
        margin-top: -20px;
    }
    .plan-name{
        padding-top: 6px;
        line-height: 20px;
    }
    .plan-container {
        padding-left: 0px;
    }
    #officePhoneNumber, #phoneNumber{
        margin-top: 16px;
    }
}
@media #{$ipad-pro-landscape} {
    .container-fluid > .col-lg-12 > .row{
        padding-top: 160px;
    }
    .row.slide.activeSlide{
        margin-left: -10px;
    }
    .col-form{
        max-height: 630px;
    }
    .title-main {
        margin-top: -100px;
    }
    .plan-name{
        padding-top: 16px;
        line-height: 20px;
    }
    .plan-container {
        padding-left: 0px;
    }
}
@media #{$phone-landscape} {
    html{
        background-color: #146c8b;
    }
    .slick-detail-col{
        max-width: 135px;
    }
    .col-phone-left > img {
        float: right;
    }
    .slide > .row {
        margin-right: -85px;
    }
}
@media #{$extra-small-phone} {
    .plan-name{
        padding-top: 6px;
        line-height: 20px;
    }
}
@media #{$extra-wide} {
    .slick-detail-col{
        width: 136px;
    }
    .col-form > div{
        padding-top: 2.5rem!important;
        padding-left: 5.5rem!important;
        padding-right: 5.5rem!important;
    }
}