@import '../../../../styles/variables';

.plan-container{
    border-radius: 20px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 1.5rem !important;
    padding-bottom: 1rem !important;
    margin-top: 1.5rem !important;
}

.plan{
    border-radius: 20px;
    background-color: #00a9e0;
}

.plan-row{
    @media #{$phone} {
        padding-left: 3rem !important;
        padding-right: 3rem !important;
    }
    @media #{$wide} {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }
}

.plan-name{
    color: rgb(255, 255, 255);
    font-weight: 500;
    background-color: rgb(0, 195, 0);
    height: 50px;
    line-height: 50px;
    border-radius: 12px;
    width: 235px;
    text-align: center;
    margin-top: -40px;
    @media #{$phone} {
        font-size: 17px;
    }
    @media #{$wide} {
        font-size: 14px;
    }
}

.plan-price{
    color: rgb(255, 255, 255);
    font-size: 40px;
    font-weight: 500;
}

.plan-price-small{
    color: rgb(255, 255, 255);
}

.plan-detail-name{
    color: rgb(255, 255, 255);
    font-size: 13px;
}

.plan-detail-divider{
    background: rgb(255, 255, 255) !important;
    margin-left: 40px;
    margin-right: 40px;
}

.plan-detail-divider-last{
    background: rgb(255, 255, 255) !important;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px !important;
}

.plan-detail-content{
    margin-top: -8px;
}

.plan-detail-content-span{
    color: #fff; 
    font-size: 36px;
}

.plan-button, .plan-button:hover, .plan-button-selected, .plan-button-selected:hover{
    color: #fff !important;      
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-bottom: 16px !important;
}

.plan-button, .plan-button:hover{
    background-color: rgb(0, 195, 0) !important;
    
}

.plan-button-selected, .plan-button-selected:hover{
    background-color: rgb(0, 117, 199) !important;
}