@import './components/steps/steps';
@import './components/phone/phone';
@import './components/plan/plan';
@import './components/contact/contact';
@import './components/address/address';
@import './components/success/success';
@import '../../styles/variables';
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css');


.form{
    @media #{$wide} {
        min-height: 400px;
        max-height: 400px;
    }
    @media #{$phone} {
        min-height: 428px;
    }
}

.form-title{
    @media #{$phone} {
        padding-top: 1rem !important;
        text-align: center;
        font-size: 22px !important;
        font-weight: 400 !important;
    }
    @media #{$wide} {
        font-size: 18px !important;
        font-weight: 400 !important;
    }
}

.error-message{
    color: #a30000;
    @media #{$wide} {
        font-size: 14px;
        padding-top: 10px;
    }
    @media #{$phone} {
        font-size: 18px;
        padding-top: 20px;
        justify-content: center;
    }
}

.next-button-container {
    @media #{$wide} {
        display: flex;
        justify-content: flex-end;
    }
}