@import '../../../../styles/variables';

#addressInput{
    @media #{$phone} {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
    @media #{$wide} {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
}

.addressInputDetail{
    @media #{$phone} {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        padding-top: 10px !important;
        padding-bottom: 20px !important;
    }
}