@import '../../../../styles/variables';

.arrow-buttons{
    padding-top: 1.5rem !important;
    @media #{$wide} {        
        min-height: 60px;    
    }
    .Mui-disabled{
        background-color: #cacaca!important;
    }
}

.slide img{
    margin: 0 auto;
}

.slide {
    transForm: scale(0.2);
    transition: transForm 300ms;
    opacity: 0.5;
    @media #{$extra-large} {
        transForm: scale(0.5);
    }
}

.activeSlide {
    transform: scale(1.0);
    opacity: 1;
    margin-left: 7.5px;
    margin-right: 7.5px;
    @media #{$phone} {        
        margin-left: 0px;
        margin-right: 0px;
        justify-content: center;
    }
    .row{
        @media #{$phone} {        
            width: 100%;
            justify-content: center;
            margin-left: 0px;
            margin-right: 0px;
        }
    }
}

.arrow {
    background-color: #fff;
    position: absolute;
    cursor: pointer;
    z-index: 10;
    background-color: #1fb5e8;
    width: 36px;
    height: 36px;
    text-align: center;
    padding-top: 6px;
    border-radius: 20px;
    color: #fff;
}

.arrow:active, .arrow:hover, .arrow:focus, .arrow svg:hover{
    color: #fff !important;
    background-color: #34beec;
}

.arrow svg{
    transition: color 300ms;
}

.arrow svg:hover{
    color: rgb(0, 114, 167);
}

.next {
    right: 0%;
    @media #{$phone} {    
        top: 45%;
    }
    @media #{$wide} {    
        top: 50%;
    }
}

.prev {
    left: 0%;
    @media #{$phone} {    
        background-color: #00536b !important;
        left: -5% !important;
        top: 45%;
    }
    @media #{$wide} {    
        top: 50%;
    }
}

.next {
    @media #{$phone} {    
        background-color: #00536b !important;
        right: -5% !important;
    }
}

.slick-col{
    padding-top: 0.5rem !important;
    padding-right: 0 !important;
    @media #{$wide} {        
        padding-left: 1.35rem !important;
    }
    @media #{$phone-fix} {
        margin-top: 0.5rem;
    }
}

.slick-detail-row{
    justify-content: center;
    text-align: center;
    font-size: 12px;
    width: 135px;
    background-color: #00a9e0;
    color: #fff;
    padding-bottom: 2px;
}

.slick-detail-row-camera{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; 
    padding-top: 20px;
}

.slick-detail-divider{
    background-color: #fff !important;
    width: 90%;
}

.slick-detail-p{
    margin-bottom: 0px !important;
    font-size: 18px;
}

.slick-detail-row-memory{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-bottom: 60px;
}

.slick-detail-col{
    height: 70px;
    background-color: #52c40f;
    color: #fff;
    border-radius: 20px; 
    vertical-align: middle; 
    text-align: center; 
    line-height: 70px; 
    font-size: 12px; 
    font-weight: 600;
    z-index: 20;
    font-weight: 500;
    @media #{$phone} {        
        width: 100% !important;
    }
}

.slick-detail-col-text{
    top: 70px; 
    min-width: 135px;
    @media #{$phone} {       
        width: 100%;
        min-width: unset;
        top: 66px;
    }
}

.slick-detail-col-span{
    line-height: 22px; 
    padding-top: 10%;
    width: 135px;
    font-size: 17px;
    @media #{$phone} {        
        width: 100% !important;
    }
}

.slick-detail-col-button{
    width: 135px;
    bottom: 18px;
}

[class="slick-slide slick-active slick-cloned"] {
    pointer-events: none;
}

[class="slick-slide slick-active"] {
    pointer-events: none;
}

.slick-list{
    @media #{$phone} {        
        padding-bottom: 14px !important;
    }
}

.phone-button, .phone-button:hover{
    background-color: rgb(0, 195, 0) !important;
    color: #fff !important;      
    padding: 6px !important;
}

.phone-button-selected, .phone-button-selected:hover{
    background-color: rgb(0, 117, 199) !important;
    color: #fff !important;       
    padding: 6px !important;
}

.col-phone-left{
    @media #{$phone} {        
        width: 50% !important;
    }
    img{
        @media #{$phone} {        
            width: 95% !important;
            height: 95% !important;
            margin-top: 20px !important;
            margin-right: 10px !important;
        }
    }
}

.col-phone-right{
    @media #{$phone} {        
        width: 50% !important;
        padding-left: 0px !important;
    }
    //fix iphone 8+
    @media only screen and (min-width: 410px) and (max-width: 417px) {
        top: 20px;
    }
}

.MuiStepConnector-alternativeLabel {
    @media #{$phone} {        
        display: none !important;
    }
}