@import '../../../../styles/variables';

label + .MuiInput-formControl {
    margin-top: 5px !important;
}

.MuiInputLabel-formControl {
    top: -10px !important;
}

.terms-link{
    font-size: 14px !important;
    position: absolute;
    margin-top: 11px;
}

.MuiFormControlLabel-label{
    font-size: 14px !important;
}

.MuiFormControlLabel-root {
    margin-right: 5px !important;
}

.contactCheckbox{
    @media #{$phone} {
        padding-bottom: 0px !important;
        padding-top: 0px !important;
    }
    @media #{$wide} {
        padding-top: 0.25rem !important;
    }
}