@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
@media all and (max-width: 575.98px) {
  .makeStyles-root-1, .makeStyles-root-2, .makeStyles-root-3, .makeStyles-root-4, .makeStyles-root-5 {
    min-height: 50px;
  }
}
@media all and (min-width: 576px) {
  .makeStyles-root-1, .makeStyles-root-2, .makeStyles-root-3, .makeStyles-root-4, .makeStyles-root-5 {
    min-height: 80px;
  }
}
.makeStyles-root-1 .MuiPaper-root, .makeStyles-root-2 .MuiPaper-root, .makeStyles-root-3 .MuiPaper-root, .makeStyles-root-4 .MuiPaper-root, .makeStyles-root-5 .MuiPaper-root {
  padding-top: 8px;
  padding-bottom: 8px;
}
@media all and (max-width: 575.98px) {
  .makeStyles-root-1 .MuiPaper-root, .makeStyles-root-2 .MuiPaper-root, .makeStyles-root-3 .MuiPaper-root, .makeStyles-root-4 .MuiPaper-root, .makeStyles-root-5 .MuiPaper-root {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media all and (min-width: 576px) {
  .makeStyles-root-1 .MuiPaper-root, .makeStyles-root-2 .MuiPaper-root, .makeStyles-root-3 .MuiPaper-root, .makeStyles-root-4 .MuiPaper-root, .makeStyles-root-5 .MuiPaper-root {
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media all and (max-width: 575.98px) {
  .makeStyles-root-1 .MuiPaper-root .MuiStep-root .MuiStepLabel-root .MuiStepLabel-labelContainer .MuiTypography-root, .makeStyles-root-2 .MuiPaper-root .MuiStep-root .MuiStepLabel-root .MuiStepLabel-labelContainer .MuiTypography-root, .makeStyles-root-3 .MuiPaper-root .MuiStep-root .MuiStepLabel-root .MuiStepLabel-labelContainer .MuiTypography-root, .makeStyles-root-4 .MuiPaper-root .MuiStep-root .MuiStepLabel-root .MuiStepLabel-labelContainer .MuiTypography-root, .makeStyles-root-5 .MuiPaper-root .MuiStep-root .MuiStepLabel-root .MuiStepLabel-labelContainer .MuiTypography-root {
    display: none;
  }
}
@media all and (max-width: 575.98px) {
  .makeStyles-root-1 .MuiPaper-root .MuiStep-root, .makeStyles-root-2 .MuiPaper-root .MuiStep-root, .makeStyles-root-3 .MuiPaper-root .MuiStep-root, .makeStyles-root-4 .MuiPaper-root .MuiStep-root, .makeStyles-root-5 .MuiPaper-root .MuiStep-root {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  margin-top: 10px !important;
}
@media all and (max-width: 575.98px) {
  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    font-size: 10px !important;
  }
}

@media all and (max-width: 575.98px) {
  .MuiStepper-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.arrow-buttons {
  padding-top: 1.5rem !important;
}
@media all and (min-width: 576px) {
  .arrow-buttons {
    min-height: 60px;
  }
}
.arrow-buttons .Mui-disabled {
  background-color: #cacaca !important;
}

.slide img {
  margin: 0 auto;
}

.slide {
  transForm: scale(0.2);
  transition: transForm 300ms;
  opacity: 0.5;
}
@media all and (min-width: 1200px) {
  .slide {
    transForm: scale(0.5);
  }
}

.activeSlide {
  transform: scale(1);
  opacity: 1;
  margin-left: 7.5px;
  margin-right: 7.5px;
}
@media all and (max-width: 575.98px) {
  .activeSlide {
    margin-left: 0px;
    margin-right: 0px;
    justify-content: center;
  }
}
@media all and (max-width: 575.98px) {
  .activeSlide .row {
    width: 100%;
    justify-content: center;
    margin-left: 0px;
    margin-right: 0px;
  }
}

.arrow {
  background-color: #fff;
  position: absolute;
  cursor: pointer;
  z-index: 10;
  background-color: #1fb5e8;
  width: 36px;
  height: 36px;
  text-align: center;
  padding-top: 6px;
  border-radius: 20px;
  color: #fff;
}

.arrow:active, .arrow:hover, .arrow:focus, .arrow svg:hover {
  color: #fff !important;
  background-color: #34beec;
}

.arrow svg {
  transition: color 300ms;
}

.arrow svg:hover {
  color: rgb(0, 114, 167);
}

.next {
  right: 0%;
}
@media all and (max-width: 575.98px) {
  .next {
    top: 45%;
  }
}
@media all and (min-width: 576px) {
  .next {
    top: 50%;
  }
}

.prev {
  left: 0%;
}
@media all and (max-width: 575.98px) {
  .prev {
    background-color: #00536b !important;
    left: -5% !important;
    top: 45%;
  }
}
@media all and (min-width: 576px) {
  .prev {
    top: 50%;
  }
}

@media all and (max-width: 575.98px) {
  .next {
    background-color: #00536b !important;
    right: -5% !important;
  }
}

.slick-col {
  padding-top: 0.5rem !important;
  padding-right: 0 !important;
}
@media all and (min-width: 576px) {
  .slick-col {
    padding-left: 1.35rem !important;
  }
}
@media all and (min-width: 390px) and (max-width: 403px) {
  .slick-col {
    margin-top: 0.5rem;
  }
}

.slick-detail-row {
  justify-content: center;
  text-align: center;
  font-size: 12px;
  width: 135px;
  background-color: #00a9e0;
  color: #fff;
  padding-bottom: 2px;
}

.slick-detail-row-camera {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 20px;
}

.slick-detail-divider {
  background-color: #fff !important;
  width: 90%;
}

.slick-detail-p {
  margin-bottom: 0px !important;
  font-size: 18px;
}

.slick-detail-row-memory {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-bottom: 60px;
}

.slick-detail-col {
  height: 70px;
  background-color: #52c40f;
  color: #fff;
  border-radius: 20px;
  vertical-align: middle;
  text-align: center;
  line-height: 70px;
  font-size: 12px;
  font-weight: 600;
  z-index: 20;
  font-weight: 500;
}
@media all and (max-width: 575.98px) {
  .slick-detail-col {
    width: 100% !important;
  }
}

.slick-detail-col-text {
  top: 70px;
  min-width: 135px;
}
@media all and (max-width: 575.98px) {
  .slick-detail-col-text {
    width: 100%;
    min-width: unset;
    top: 66px;
  }
}

.slick-detail-col-span {
  line-height: 22px;
  padding-top: 10%;
  width: 135px;
  font-size: 17px;
}
@media all and (max-width: 575.98px) {
  .slick-detail-col-span {
    width: 100% !important;
  }
}

.slick-detail-col-button {
  width: 135px;
  bottom: 18px;
}

[class="slick-slide slick-active slick-cloned"] {
  pointer-events: none;
}

[class="slick-slide slick-active"] {
  pointer-events: none;
}

@media all and (max-width: 575.98px) {
  .slick-list {
    padding-bottom: 14px !important;
  }
}

.phone-button, .phone-button:hover {
  background-color: rgb(0, 195, 0) !important;
  color: #fff !important;
  padding: 6px !important;
}

.phone-button-selected, .phone-button-selected:hover {
  background-color: rgb(0, 117, 199) !important;
  color: #fff !important;
  padding: 6px !important;
}

@media all and (max-width: 575.98px) {
  .col-phone-left {
    width: 50% !important;
  }
}
@media all and (max-width: 575.98px) {
  .col-phone-left img {
    width: 95% !important;
    height: 95% !important;
    margin-top: 20px !important;
    margin-right: 10px !important;
  }
}

@media all and (max-width: 575.98px) {
  .col-phone-right {
    width: 50% !important;
    padding-left: 0px !important;
  }
}
@media only screen and (min-width: 410px) and (max-width: 417px) {
  .col-phone-right {
    top: 20px;
  }
}

@media all and (max-width: 575.98px) {
  .MuiStepConnector-alternativeLabel {
    display: none !important;
  }
}

.plan-container {
  border-radius: 20px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 1.5rem !important;
  padding-bottom: 1rem !important;
  margin-top: 1.5rem !important;
}

.plan {
  border-radius: 20px;
  background-color: #00a9e0;
}

@media all and (max-width: 575.98px) {
  .plan-row {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
}
@media all and (min-width: 576px) {
  .plan-row {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}

.plan-name {
  color: rgb(255, 255, 255);
  font-weight: 500;
  background-color: rgb(0, 195, 0);
  height: 50px;
  line-height: 50px;
  border-radius: 12px;
  width: 235px;
  text-align: center;
  margin-top: -40px;
}
@media all and (max-width: 575.98px) {
  .plan-name {
    font-size: 17px;
  }
}
@media all and (min-width: 576px) {
  .plan-name {
    font-size: 14px;
  }
}

.plan-price {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-weight: 500;
}

.plan-price-small {
  color: rgb(255, 255, 255);
}

.plan-detail-name {
  color: rgb(255, 255, 255);
  font-size: 13px;
}

.plan-detail-divider {
  background: rgb(255, 255, 255) !important;
  margin-left: 40px;
  margin-right: 40px;
}

.plan-detail-divider-last {
  background: rgb(255, 255, 255) !important;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px !important;
}

.plan-detail-content {
  margin-top: -8px;
}

.plan-detail-content-span {
  color: #fff;
  font-size: 36px;
}

.plan-button, .plan-button:hover, .plan-button-selected, .plan-button-selected:hover {
  color: #fff !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin-bottom: 16px !important;
}

.plan-button, .plan-button:hover {
  background-color: rgb(0, 195, 0) !important;
}

.plan-button-selected, .plan-button-selected:hover {
  background-color: rgb(0, 117, 199) !important;
}

label + .MuiInput-formControl {
  margin-top: 5px !important;
}

.MuiInputLabel-formControl {
  top: -10px !important;
}

.terms-link {
  font-size: 14px !important;
  position: absolute;
  margin-top: 11px;
}

.MuiFormControlLabel-label {
  font-size: 14px !important;
}

.MuiFormControlLabel-root {
  margin-right: 5px !important;
}

@media all and (max-width: 575.98px) {
  .contactCheckbox {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
}
@media all and (min-width: 576px) {
  .contactCheckbox {
    padding-top: 0.25rem !important;
  }
}

@media all and (max-width: 575.98px) {
  #addressInput {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}
@media all and (min-width: 576px) {
  #addressInput {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

@media all and (max-width: 575.98px) {
  .addressInputDetail {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    padding-top: 10px !important;
    padding-bottom: 20px !important;
  }
}

.spinner {
  text-align: center !important;
}

.spinner > img {
  width: 100px;
  margin-top: 12%;
}
@media all and (max-width: 575.98px) {
  .spinner > img {
    margin-bottom: 20%;
    margin-top: 33%;
  }
}

.successImage {
  margin-top: 1rem !important;
}

.errorMessage {
  margin-top: 20%;
}

@media all and (min-width: 576px) {
  .form {
    min-height: 400px;
    max-height: 400px;
  }
}
@media all and (max-width: 575.98px) {
  .form {
    min-height: 428px;
  }
}

@media all and (max-width: 575.98px) {
  .form-title {
    padding-top: 1rem !important;
    text-align: center;
    font-size: 22px !important;
    font-weight: 400 !important;
  }
}
@media all and (min-width: 576px) {
  .form-title {
    font-size: 18px !important;
    font-weight: 400 !important;
  }
}

.error-message {
  color: #a30000;
}
@media all and (min-width: 576px) {
  .error-message {
    font-size: 14px;
    padding-top: 10px;
  }
}
@media all and (max-width: 575.98px) {
  .error-message {
    font-size: 18px;
    padding-top: 20px;
    justify-content: center;
  }
}

@media all and (min-width: 576px) {
  .next-button-container {
    display: flex;
    justify-content: flex-end;
  }
}

@media all and (min-width: 576px) {
  .title-main {
    margin-top: -30px;
  }
}
@media all and (max-width: 1199.98px) {
  .title-main {
    margin-top: 40px;
  }
}
@media all and (max-width: 575.98px) {
  .title-main .title-row-title {
    padding-top: 3rem !important;
  }
}
.title-main .title-row-title h1 {
  font-size: 45px;
  font-weight: 500;
  color: #fff;
  line-height: 46px;
}
.title-main .title-row-description > .col-12 > .row > h5 {
  font-size: 20px;
  font-weight: 300;
  color: #fff;
  line-height: 30px;
}

.logo-movistar {
  width: 66%;
}
@media all and (min-width: 576px) {
  .logo-movistar {
    margin-bottom: 40px;
  }
}

.MuiStepper-root {
  padding-bottom: 24px !important;
  padding-top: 14px !important;
}

@media all and (max-width: 575.98px) {
  html, body, #root, .App {
    height: 100% !important;
    background-color: rgb(0, 107, 138);
  }
}

body {
  font-family: "Roboto", sans-serif;
}

.App .container-fluid {
  height: inherit;
}
.App .container-fluid #hero-diagonal {
  position: relative;
}
.App .container-fluid #hero-diagonal::before {
  content: "";
  position: fixed;
  top: -50px;
  left: 0;
  width: 100%;
  height: calc(100% + 100px);
  clip-path: polygon(100% 0, 40% 0, 100% 65%);
}
@media all and (max-width: 575.98px) {
  .App .container-fluid #hero-diagonal::before {
    display: none;
  }
}
.App .container-fluid #hero-diagonal #hero-content {
  position: relative;
  z-index: 1;
}
@media all and (min-width: 576px) {
  .App .container-fluid {
    height: 100vh;
  }
}
.App .container-fluid .col-lg-12 .row .col-form {
  padding-bottom: 4rem !important;
  padding-top: 3rem !important;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 0.25rem !important;
  background-color: #fff !important;
}
@media all and (min-width: 576px) {
  .App .container-fluid .col-lg-12 .row .col-form {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
}
@media all and (max-width: 575.98px) {
  .App .container-fluid .col-lg-12 .row .col-form {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media all and (min-width: 1919px) {
  .App .container-fluid .col-lg-12 .row .col-form {
    padding-bottom: 7rem !important;
  }
}

*:focus {
  outline: none !important;
  outline: 0 !important;
}

@media all and (min-width: 768px) and (max-width: 768px) and (orientation: portrait) {
  html {
    background-color: #146c8b;
  }
  .slick-slide.slick-active.slick-center.slick-current {
    margin-left: -22px;
  }
}
@media all and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) {
  .container-fluid > .col-lg-12 > .row {
    padding-top: 310px;
  }
  .row.slide.activeSlide {
    margin-left: -37px;
  }
  .col-form {
    max-height: 630px;
  }
  .title-main {
    margin-top: -160px;
  }
  .plan-name {
    padding-top: 6px;
    line-height: 20px;
  }
  .plan-container {
    padding-left: 0px;
  }
  #officePhoneNumber, #phoneNumber {
    margin-top: 16px;
  }
}
@media all and (min-width: 1024px) and (max-width: 1024px) and (orientation: landscape) {
  .row.slide.activeSlide {
    margin-left: -37px;
  }
  .container-fluid > .col-lg-12 > .row {
    padding-top: 50px;
  }
  .title-main {
    margin-top: -20px;
  }
  .plan-name {
    padding-top: 6px;
    line-height: 20px;
  }
  .plan-container {
    padding-left: 0px;
  }
  #officePhoneNumber, #phoneNumber {
    margin-top: 16px;
  }
}
@media all and (min-device-width: 1366px) and (max-device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .container-fluid > .col-lg-12 > .row {
    padding-top: 160px;
  }
  .row.slide.activeSlide {
    margin-left: -10px;
  }
  .col-form {
    max-height: 630px;
  }
  .title-main {
    margin-top: -100px;
  }
  .plan-name {
    padding-top: 16px;
    line-height: 20px;
  }
  .plan-container {
    padding-left: 0px;
  }
}
@media all and (max-width: 736px) and (orientation: landscape) {
  html {
    background-color: #146c8b;
  }
  .slick-detail-col {
    max-width: 135px;
  }
  .col-phone-left > img {
    float: right;
  }
  .slide > .row {
    margin-right: -85px;
  }
}
@media all and (max-width: 360px) {
  .plan-name {
    padding-top: 6px;
    line-height: 20px;
  }
}
@media all and (min-width: 1919px) {
  .slick-detail-col {
    width: 136px;
  }
  .col-form > div {
    padding-top: 2.5rem !important;
    padding-left: 5.5rem !important;
    padding-right: 5.5rem !important;
  }
}

